//
// _ecommerce.scss
//

// product card ukauji

// swiper
.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// end swiper

.uk-product-main {
    @media (max-width: 512px) {
      justify-content: center !important;
    }
  }
  
  .xs-p {
    @media (max-width:375px) {
      display: flex;
      flex-direction: column;
      // align-items: center !important;
      justify-content: center !important;
      align-content: center !important;
    }
    @media (max-width:512px) {
      align-items: center !important;
    }
  }
  
  .gap-uk {
    gap: 1.5rem !important;
    @media (max-width:968px) {
      gap: 0.9rem !important;
    }
    @media (max-width:768px) {
      gap: 0.7rem !important;
    }
    @media (max-width:512px) {
      gap: 0.2rem !important;
    }
  }
  .img-detail {
    display: block !important;
    width: 350px !important;
    height: 350px !important;
  
    @media (max-width:968px) {
      display: block !important;
      width: 400px !important;
      height: 400px !important;
    }
  
    @media (max-width:768px) {
      display: block !important;
      width: 360px !important;
      height: 360px !important;
    }
  
    @media (max-width:512px) {
      display: block !important;
      width: 300px !important;
      height: 300px !important;
      margin-left: 10px;
    }
  }
  
  .product-ui {
    display: block;
    width: 300px;
    height: 400px;
  
    img {
      display: block;
      width: 300px;
      height: 300px;
      box-sizing: border-box;
    }
  
    @media (max-width:512px) {
      display: block !important;
      width: 150px !important;
      height: 329px !important;
  
      img {
        display: block !important;
        width: 150px !important;
        height: 150px !important;
      }
    }
  
  
  
    @media (max-width:768px) {
      display: block !important;
      width: 200px;
      height: 340px;
  
      img {
        display: block !important;
        width: 200px;
        height: 200px;
      }
    }
  
    @media (max-width:375px) {
      display: block !important;
      width: 200px !important;
      height: 330px !important;
  
      img {
        display: block !important;
        width: 200px !important;
        height: 200px !important;
      }
    }
  
  }
  
  .uk-card-product {
    display: block;
    width: 300px;
    height: 450px;
    padding-bottom: 15px;
    
    .reviews {
      position: absolute;
      right: 0;
      top: 0;
      width: 120px;
      height: 30px;
      padding: 10px;
      background-color: rgb(255, 170, 66);
    }
    img {
     display: block !important;
     width: 300px;
     height: 300px;
     object-fit: contain;
    }
  
    @media (max-width: 512px) {
      width: 280px;
      height: 430px;
  
      img {
          display: block ;
          width: 280px ;
          height: 280px;
          object-fit: contain;
      }
    }
  }
  .swipper-uk {
    display: block !important;
    width: 100% !important;
    height: 100% !important ;
  
    @media (max-width:512px) {
      display: block !important;
      width: 100% !important;
      height: 100% !important ;
    }
  }
  .swipper-uk-preview {
    display: block !important;
    width: 60px !important;
    height: 60px !important;
  }
  .img-u-detail {
    max-width: 100% !important;
    height: 12 rem !important;
    object-fit: contain !important;
  }


// product list

#selection-element { 
    display: none;
}

.filter-choices-input{
    .choices__inner{
        padding: 0;
        border: none;
        background-color: var(--#{$prefix}card-bg-custom);
    }

    .choices__input{
        background-color: var(--#{$prefix}card-bg-custom);
    }
}

//  product detail

.sticky-side-div{
    @media (min-width: 992px){
        position: sticky;
        top: calc(#{$header-height} + #{$grid-gutter-width});
    }
}

.product-img-slider{
    .product-nav-slider{
        .nav-slide-item{
            border: 1px solid $border-color;
            border-radius: $border-radius;
            padding: 0.5rem;
            cursor: pointer;
        }

        .swiper-slide-thumb-active{
            .nav-slide-item{
                background-color: var(--#{$prefix}light);
            }
        }
    }
}

.filter-list{
    a {
        &.active {
            .listname {
                color: $success;
            }
        }   
    }
}